import React from "react";
var __jsx = React.createElement;
import Link from 'next/link';
import styled from 'styled-components';
import { BreadCrumbs, Button, Container, LinkButton, breakpoint, styles as S, stickers } from '@bob/design-system';
import * as url from '@bob/url';
export default function Custom404() {
  return __jsx(React.Fragment, null, __jsx(Container, null, __jsx(BreadCrumbs, {
    crumbs: url.notFound().crumbs
  })), __jsx(Section, null, __jsx(StickerBlock, null, __jsx(stickers.others.NotFound, {
    size: 162
  })), __jsx("div", null, __jsx(S.heading.Title2, null, "Oups, vous \xEAtes perdu\xB7e ?"), __jsx(S.copy.Text5, null, "La page que vous recherchez n\u2019existe pas, ou plus. Pas de panique, on vous montre le chemin !"), __jsx(LinkGroup, null, __jsx(Link, {
    prefetch: false,
    href: url.homepage().path
  }, __jsx("a", null, __jsx(Button, null, "Retourner à l'accueil"))), __jsx(LinkButton, {
    href: url.faq().path
  }, "Visiter la FAQ")))));
}
var Section = styled.section.withConfig({
  displayName: "sc-404__Section",
  componentId: "sc-33bwdx-0"
})(["margin-bottom:80px;margin-top:25px;padding:0px 20px;@media screen and (", "){display:flex;flex-direction:row-reverse;justify-content:center;margin-top:60px;}", "{max-width:250px;@media screen and (", "){max-width:100%;}}", "{margin:26px 0px 36px;@media screen and (", "){max-width:410px;}}", "{height:184px;width:184px;@media screen and (", "){height:262px;width:262px;margin-left:20px;}}"], breakpoint.TABLET, S.heading.Title2, breakpoint.TABLET, S.copy.Text5, breakpoint.TABLET, stickers.others.NotFound, breakpoint.TABLET);
var StickerBlock = styled.div.withConfig({
  displayName: "sc-404__StickerBlock",
  componentId: "sc-33bwdx-1"
})(["align-items:center;display:flex;justify-content:center;margin-bottom:30px;"]);
var LinkGroup = styled.div.withConfig({
  displayName: "sc-404__LinkGroup",
  componentId: "sc-33bwdx-2"
})(["align-items:center;display:flex;flex-direction:column;margin-bottom:80px;padding:0px 20px;@media screen and (", "){flex-direction:row;margin-bottom:0px;padding:0px;}", "{width:296px;}", "{margin-top:38px;@media screen and (", "){margin-left:32px;margin-top:0px;}}"], breakpoint.TABLET, Button, LinkButton, breakpoint.TABLET);